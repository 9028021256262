import React, { useState } from 'react';
import { toast } from '@hum/common/src/modules/toast';
import { Detail, Separator } from '@hum/legacy-ui';
import { SettingsPage, SettingsSection } from '../styles.pc';
import {
  UpdateUserSettingsModal,
  UpdateEmailModal,
} from './UpdateUserSettings';

import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { recoverAccount, useApiMutation } from '@hum/api';

export const Account = () => {
  const [updateAccountModal, setUpdateAccountModal] = useState(false);
  const [updateEmailModal, setUpdateEmailModal] = useState(false);
  const flags = useFlags();
  const { mutate: recover } = useApiMutation(recoverAccount);

  const {
    state: { session },
    dispatch,
  } = useAppStore();
  const currentUser = session.data;
  const api = useAPI();

  const updatePassword = async () => {
    try {
      if (currentUser) {
        await api.auth.recoverAccount(dispatch)({ email: currentUser?.email });
        toast.success(
          `An email has been sent to ${currentUser?.email} for password reset`
        );
      }
    } catch (e: any) {
      toast.error(e.message);
    }
  };
  const info = {
    Contact: {
      details: {
        Name: currentUser?.firstName,
        Lastname: currentUser?.lastName,
        Phone: currentUser?.phone,
        Position: currentUser?.position,
      },
      button: {
        caption: 'Edit profile',
        onClick: () => setUpdateAccountModal(true),
      },
    },
    Email: {
      details: {
        'Your registered email is': currentUser?.email,
      },
      button: {
        caption: 'Change email',
        onClick: () => setUpdateEmailModal(true),
      },
    },
    Password: {
      details: {
        'Password reset email': '',
      },
      button: {
        caption: 'Reset password',
        onClick: () =>
          flags.enabled('use-react-query')
            ? recover({
                email: currentUser?.email,
              })
            : updatePassword,
      },
    },
  };

  return (
    <>
      <SettingsPage>
        {Object.entries(info).map(([title, section]) => (
          <div key={title}>
            <SettingsSection
              title={title}
              onClick={section.button.onClick}
              caption={section.button.caption}
            >
              {Object.entries(section.details).map(([detail, data]: any) => (
                <Detail
                  key={detail}
                  horizontal
                  padded
                  title={
                    detail === 'Your registered email is'
                      ? `${detail} ${data}`
                      : detail
                  }
                  description={
                    detail === 'Your registered email is' ? '' : data
                  }
                  fullwidth={detail === 'Your registered email is'}
                  testId={`user-settings:${detail}`}
                />
              ))}
            </SettingsSection>
            <Separator />
          </div>
        ))}
      </SettingsPage>
      <UpdateUserSettingsModal
        isOpen={updateAccountModal}
        onClose={() => setUpdateAccountModal(false)}
        currentUser={currentUser}
      />
      <UpdateEmailModal
        isOpen={updateEmailModal}
        onClose={() => setUpdateEmailModal(false)}
      />
    </>
  );
};
